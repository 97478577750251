<template>
    <div v-if="listData.length" style="margin: 80px 0;">
        <div class="title">Limit Discounts</div>
        <el-carousel :autoplay="false" indicator-position="none" :loop="false" arrow="always" style="height: 320px;">
            <el-carousel-item v-for="(v, i) in listData" :key="i">
                <div class="item" v-for="(item, index) in v" :key="index"
                    @click="$router.pushToTab({ path: `/sku-${item.sku_id}` })">

                    <div class="img">
                        <el-image style="height: 240px;" :src="item.sku_image" fit="fit"></el-image>
                    </div>
                    <div class="text">
                        <p>{{ item.sku_name }}</p>
                        <span>EUR{{ item.discount_price }}</span>
                        <span>EUR{{ item.price }}</span>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
    name: "LimitedTime",
    props: {
        LimitedList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            listData: [],
            classOption: {
                limitMoveNum: 3,
                direction: 2,
                // step: 1
            }
        }
    },
    watch: {
        LimitedList(newValue) {
            // console.log(newValue);
            // this.listData = newValue;
            this.listData = this.divisionArr(newValue, 5);
        }
    },
    created() {
        // console.log(this.LimitedList);
    },
    methods: {
        divisionArr(arr, num) {
            let newArr = [...arr] // 因为splice会改变原数组，要深拷贝一下
            let list = []
            for (let i = 0; i < newArr.length;) {
                list.push(newArr.splice(i, num))
            }
            return list
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    // font-family: 'Courier New', Courier, monospace;
    font-family: Tiemann, serif;
    font-size: 30px;
    // font-size: 1.88125em;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .025em;
    text-align: center;
    margin: 20px 0;
}

.el-carousel__item {
    display: flex;
    height: 320px;


    .item {
        width: calc((100% - 10px) / 5);
        box-sizing: border-box;
        border: 1px solid #fff;
        padding: 10px 0;
        text-align: center;

        .img {
            height: 240px;
            overflow: hidden;
        }

        .text {
            margin: 5px 0;

            p {
                font-size: 12px;
            }

            span {
                color: #FF547B;

                &:nth-of-type(2) {
                    margin-left: 10px;
                    color: #7d7c7c;
                    text-decoration: line-through;
                }
            }


        }
    }

    .item:hover {
        box-sizing: border-box;
        border: 1px solid #FF547B;
    }
}
</style>